<!--
 * @Author: Dyf
 * @LastEditors: Dyf
 * @Date: 2023-02-28 20:35:55
 * @LastEditTime: 2023-07-06 11:25
 * @Descripttion: 公开课
-->
<style lang="scss" scoped>
.public-class {
    @include innerPage;
    @include pageHead(-10px);
    @include pageFoot;

    .page-head {
        .head-wrapper.column {
            margin-top: -18px;
        }

    }

    &-list {
        width: 100%;
        height: calc(100% - 236px);
        box-sizing: border-box;
        box-sizing: border-box;
        padding: 42px 0 20px;

        &.no-data {
            border-radius: 10px;
            background: #fff;
            height: calc(100% - 296px);
            margin: 30px 0;
        }

        .list-inner {
            width: calc(100% + 20px);
            height: 100%;
            overflow: hidden;
            overflow-y: auto;
            box-sizing: border-box;
            padding-right: 20px;
            flex-wrap: wrap;
            @include flexBox(space-between, flex-start);

            &::after {
                content: '';
                width: 506px;
            }

            .item {
                width: 506px;
                height: 286px;
                border-radius: 10px;
                background: #fff;
                margin-bottom: 36px;
                overflow: hidden;

                .title {
                    height: 60px;
                    box-sizing: border-box;
                    padding-left: 56px;
                    padding-right: 20px;
                    background: #f6f7fc;
                    position: relative;
                    @include flexBox;

                    &::before {
                        content: '\e62f';
                        font-family: 'iconfont';
                        font-size: 28px;
                        color: rgba($color: #44269a, $alpha: .21);
                        line-height: 64px;
                        position: absolute;
                        left: 24px;
                        top: 0;
                    }

                    h4 {
                        flex-grow: 1;
                    }

                    .el-tag {
                        flex-shrink: 0;
                        margin-left: 20px;
                    }
                }

                .about-info {
                    height: calc(100% - 146px);
                    box-sizing: border-box;
                    padding-left: 60px;
                    padding-right: 18px;
                    flex-direction: column;
                    @include flexBox(center, flex-start);

                    li {
                        position: relative;
                        box-sizing: border-box;
                        padding-left: 14px;
                        line-height: 30px;
                        color: #595959;

                        &::before {
                            content: '';
                            width: 8px;
                            height: 8px;
                            background: #e8eaf3;
                            border-radius: 50%;
                            position: absolute;
                            left: 0;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                    }
                }

                .foot {
                    width: calc(100% - 36px);
                    height: 86px;
                    margin: 0 auto;
                    box-sizing: border-box;
                    border-top: 1px solid rgba($color: #e1dced, $alpha: 0.8);
                    @include flexBox(space-between);

                    .view-persons {
                        width: 150px;
                        margin-right: auto;
                        @include flexBox;

                        .avatar {
                            border: 1px solid #ececec;
                            border-radius: 50%;
                            flex-shrink: 0;
                            margin-left: -10px;
                            @include flexBox(center);

                            &:first-child {
                                margin-left: 0;
                            }
                        }

                        .ellipsis {
                            margin-left: auto;
                            @include flexBox;

                            i {
                                display: inline-block;
                                width: 6px;
                                height: 6px;
                                background: #d7d7d7;
                                border-radius: 50%;
                                margin-left: 2px;
                            }
                        }
                    }

                    .view-num {
                        color: #7a7a7a;
                        line-height: 24px;
                        padding-right: 16px;
                        border-right: 1px solid rgba($color: #7a7a7a, $alpha: .11);
                        margin-right: 22px;
                        margin-left: auto;
                        flex-shrink: 0;

                        .pf_bold {
                            font-size: 22px;
                            color: #44269a;
                        }
                    }
                }
            }
        }
    }
}
</style>

<template>
    <section class="public-class">
        <div class="page-head">
            <img class="bitmap" src="@assets/images/bitmap-head.png" alt="">
            <div class="head-wrapper column">
                <breadcrumb />
                <div class="wrap-plate">
                    <div class="single-search">
                        <div class="search-ipt">
                            <div class="grade-select">
                                <el-dropdown placement="bottom-start" trigger="click"
                                    @visible-change="dropdownVisible = !dropdownVisible">
                                    <p class="select-result el-dropdown-link">
                                        <span>{{ currentGrade.grade_name }}</span>
                                        <i class="iconfont" :class="{ revolve: dropdownVisible }">&#xe635;</i>
                                    </p>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item :class="{ current: currentGrade.grade_id == -1 }"
                                            @click.native="changeGrade({ grade_id: -1, grade_name: '全部' })">
                                            全部
                                        </el-dropdown-item>
                                        <el-dropdown-item :class="{ current: currentGrade.grade_id == item.grade_id }"
                                            v-for="item in gradeList" :key="item.grade_id"
                                            @click.native="changeGrade(item)">
                                            {{ item.grade_name }}
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </div>
                            <el-input v-model.trim="searchKey" maxlength="100" placeholder="搜索课程名称" clearable
                                @clear="searchData">
                                <span slot="prefix" class="iconfont">&#xe61c;</span>
                            </el-input>
                        </div>
                        <el-button type="custom_primary" size="medium" @click="searchData">搜索</el-button>
                    </div>
                </div>
            </div>
        </div>
        <div class="public-class-list" :class="{ 'no-data': !tableData.length }">
            <ul class="list-inner" v-if="!$isEmpty(tableData)">
                <li class="item" v-for="item in tableData" :key="item.teope_id">
                    <div class="title">
                        <h4 class="bold line-text--1st">{{ item.sccou_cha_titles_name || '-' }}</h4>
                        <el-tag>{{ item.sysub_name || '-' }}</el-tag>
                    </div>
                    <ul class="about-info">
                        <li>班级：{{ item.teope_grade }}{{ item.sccla_name }}</li>
                        <li>教师：{{ item.teuse_name }}</li>
                        <li>时间：{{ item.tecla_start_time }}-{{ item.tecla_end_time }}</li>
                    </ul>
                    <div class="foot">
                        <ul class="view-persons" v-if="!$isEmpty(item.browsers_avatar)">
                            <li class="avatar" v-for="(a, index) in item.browsers_avatar" :key="index">
                                <el-avatar :size="44" :src="formatfile(a)">
                                    <img src="@assets/images/empty_avatar.png" />
                                </el-avatar>
                            </li>
                            <li class="ellipsis" v-if="item.teope_readnum.length > 3">
                                <i></i>
                                <i></i>
                                <i></i>
                            </li>
                        </ul>
                        <p class="view-num">
                            <span class="pf_bold">{{ item.teope_readnum }}</span>
                            <span>人看过</span>
                        </p>
                        <el-button type="custom_primary" size="small"
                            @click="$router.push({ name: 'PUBLIC_CLASS_DETAIL', params: { id: item.teope_id } })">
                            进去观看
                        </el-button>
                    </div>
                </li>
            </ul>
            <div class="no-data--empty" v-if="$isEmpty(tableData)">
                <img src="@assets/images/no-data3.png" alt="">
                <p>暂无公开课数据哦~</p>
            </div>
        </div>
        <!-- 分页控件 -->
        <div class="custom-foot">
            <customPagination :current='tablePage.pageIndex' :total="tablePage.total" :size="tablePage.pageSize" @pageChange="flippingPage" />
        </div>
    </section>
</template>

<script>
import { $getPublicClass } from "@api/publicClass"
import { mapState } from "vuex"
import { formatFile } from "@utils"
import customPagination from "@comp/customPagination"
export default {
    name: 'publicClass',
    components: { customPagination },
    computed: {
        ...mapState('common', ['gradeList']),
        formatfile() {
            return function (url) {
                return formatFile(url)
            }
        }
    },
    data() {
        return {
            tableData: [], // 课件列表数据
            /* 表格分页参数 */
            tablePage: {
                pageIndex: 1,
                pageSize: 9,
                total: 0
            },
            currentGrade: { grade_id: -1, grade_name: '全部' },
            searchKey: '',
            dropdownVisible: false,
        }
    },
    created() {
        this.getData();
    },
    methods: {
        /** 获取公开课列表数据 */
        async getData() {
            let params = {
                query_word: this.searchKey,
                pageindex: this.tablePage.pageIndex
            }
            if (this.currentGrade.grade_id > 0) params.teope_grade = this.currentGrade.grade_id;
            let { data: res } = await $getPublicClass(params,this.tablePage.pageSize);
            this.tablePage.total = res.allcount;
            this.tableData = res.data;
            this.$forceUpdate();
        },
        /**
         * 筛选年级
         * @param {object} val 年级信息
         */
        changeGrade(val) {
            if (this.currentGrade.grade_id == val.grade_id) return;
            this.currentGrade = val;
            this.searchData();
        },
        /** 翻页 */
        flippingPage(val) {
            this.tablePage.pageIndex = val;
            this.getData();
        },
        /** 搜索 */
        searchData() {
            this.tableData = [];
            this.tablePage.pageIndex = 1;
            this.getData();
        },
    },
}
</script>