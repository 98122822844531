/*
 * @Descripttion: 公开课模块接口
 * @Author: Dyf
 * @Date: 2023-04-12
 * @LastEditors: Dyf
 * @LastEditTime: 2023-04-14
 */
import HTTP from "@utils/http";
import { PAGE_SIZE } from "@config";

const PREFIX = "/teacher/teaopenclass/";

/*******
 * @Descripttion: 获取公开课列表
 * @Author: Dyf
 * @param {object} params
 * @param {number} pagesize
 * @return {*}
 */
export async function $getPublicClass(params, pagesize = PAGE_SIZE) {
  try {
    return await HTTP.post(
      `${PREFIX}list`,
      { ...params, pagesize },
      {
        load: true,
        loadEl: ".public-class-list",
      }
    );
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 公开课详情
 * @Author: Dyf
 * @param {number} teope_id 公开课id
 * @return {*}
 */
export async function $publicClassDetail(teope_id) {
  try {
    return await HTTP.post(`${PREFIX}info`, { teope_id });
  } catch (error) {
    return error;
  }
}
